/**
 * React to guest demo expired event
 * Take registrationLink from get-registry and redirect to it
 * But this registrationLink could be false
 */
import React from 'react'
import { t } from 'ttag'
import { ThemeContextConsumer } from '../../ThemeContext'
import { Overlay } from '@react-md/overlay'
import { connect } from 'react-redux'
import { actionCloseModal } from '../../../actions/modal'
import { Contents, Caption, Modal, SubmitButton, Paragraph } from './styled'
import { actionCloseAllTrades } from '../../../actions/trading'

interface ICloseAllTrade {
  walletId: number
  actionCloseAllTrades: (walletId: number) => void
  actionCloseModal: () => void
}
const CloseAllTrade = (props: ICloseAllTrade) => {
  const handleCloseTrade = () => {
    props.actionCloseAllTrades(props.walletId)
  }
  return (
    <>
      <Modal>
        <div>
          <ThemeContextConsumer>
            {(context) => (
              <Contents backgroundColor={context.modalBackground}>
                <Caption
                  color={context.primaryText}
                >{t`Do you want to close all of your trade?`}</Caption>

                <Paragraph colors={context}>
                  {t`Your trades will be closed at current price, only positions that are currently active will be affected, the rest will remain open.`}
                </Paragraph>
                <SubmitButton colors={context} onClick={handleCloseTrade}>
                  {t`Close All`}
                </SubmitButton>
              </Contents>
            )}
          </ThemeContextConsumer>
        </div>
      </Modal>
      <Overlay
        id="modal-overlay"
        visible={true}
        onRequestClose={props.actionCloseModal}
        style={{ zIndex: 40 }}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  walletId: state.wallets.activeWallet?.walletID,
})

export default connect(mapStateToProps, {
  actionCloseModal,
  actionCloseAllTrades,
})(CloseAllTrade)
