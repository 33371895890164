import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getInstrumentObject } from '../../selectors/instruments'
import { getTrackBackground, Range } from 'react-range'
import { IInstrument } from '../../../core/API'
import { MarkerWraper, SliderContainer } from './styled'

interface ICurrencyInputProps {
  decimalsLimit?: number
  decimalScale?: number
}

interface SliderLeverageProps {
  value: any
  colors: any
  onChangeValue?: (value: any) => void
  onChangeRange?: (value: any, isSlider: boolean) => void
  currencyInputProps?: ICurrencyInputProps
  currentInstrument: IInstrument
  sliderStep?: number
  prefixInput?: string
  options: number[]
}

const SliderLeverage = (props: SliderLeverageProps) => {
  const { options, value, sliderStep } = props

  const [hoverIndex, setHoverIndex] = useState<number | null>(null)

  const selectedIndex = options.indexOf(value)
  return (
    <SliderContainer colors={props.colors}>
      <div className="title">Set Leverage</div>
      <div className={`input-range`}>
        <Range
          onChange={(values: number[]) => {
            const closestValue = options[values[0]]
            props.onChangeRange && props.onChangeRange(closestValue, true)
          }}
          min={0}
          max={options.length > 1 ? options.length - 1 : 1}
          step={sliderStep}
          values={[selectedIndex]}
          renderTrack={({ props: rangeProps, children }) => (
            <div
              onMouseDown={rangeProps.onMouseDown}
              onTouchStart={rangeProps.onTouchStart}
              style={{
                ...rangeProps.style,
                height: '14px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={rangeProps.ref}
                style={{
                  height: '6px',
                  width: '100%',
                  borderRadius: '3px',
                  background: getTrackBackground({
                    values: [selectedIndex],
                    colors: [
                      props.colors.accentDefault,
                      props.colors.tradebox.fieldBackground,
                    ],
                    min: 0,
                    max: options.length > 1 ? options.length - 1 : 1,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
              {/* Small Circular Markers */}
              {options.map((opt, index) => {
                const isSelected = index <= selectedIndex
                const isHovered = hoverIndex === index
                const leftOffset = `${(index / (options.length - 1)) * 100}%`
                const isLast = options.length - 1 === index
                const isFirst = index === 0
                return (
                  <MarkerWraper
                    leftOffset={leftOffset}
                    isSelected={isSelected}
                    isHovered={isHovered}
                    colors={props.colors}
                    key={index}
                    index={index}
                    isFirst={isFirst}
                    isLast={isLast}
                  >
                    <div
                      className="marker"
                      onMouseEnter={() => {
                        if (index > selectedIndex) {
                          setHoverIndex(index)
                        }
                      }}
                      onMouseLeave={() => setHoverIndex(null)}
                    />

                    {/* Label Below Marker */}
                    <div className="marker-label">1x{opt}</div>
                  </MarkerWraper>
                )
              })}
            </div>
          )}
          renderThumb={({ props: rangeProps }) => (
            <div {...rangeProps} className="slider-thumb" />
          )}
        />
      </div>
    </SliderContainer>
  )
}

const mapStateToProps = (state: any) => ({
  colors: state.theme,
  currentInstrument: getInstrumentObject(state),
})

export default connect(mapStateToProps, {})(SliderLeverage)
