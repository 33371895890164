import styled from 'styled-components'

const Modal = styled.div<any>`
  position: absolute;
  width: 380px;
  max-height: 405px;
  z-index: 41;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // display: block;
`

const Contents = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
  // max-height: 405px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: 0 2px 10px 0 rgba(20, 31, 44, 0.8);
`
const Paragraph = styled.p<{ colors: any }>`
  color: ${(props) => props.colors.sidebarLabelText};
  font-size: 14px;
`

const Caption = styled.h2<any>`
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.12px;
  color: ${(props) => props.color};
`

const SubmitButton = styled.button<{ colors: any }>`
  border: none;
  outline: none;
  align-self: end;
  margin-top: 2px;
  // display: block;
  // flex: 1 1 auto;
  min-width: 115px;
  height: 42px;
  line-height: 25px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.07px;
  text-align: center;
  border-radius: 2px;
  color: ${(props) => props.colors.secondary};
  background: ${(props) => props.colors.secondaryButton};
  border: solid 1px ${(props) => props.colors.secondaryButton};
  cursor: pointer;
`

export { Modal, Contents, Caption, SubmitButton, Paragraph }
